<template>
  <div>
    <div class="d-flex ">
      <v-icon
        class="mr-4 align-self-start"
        color="success"
        size="60"
      >
        mdi-check-circle
      </v-icon>
      <div>
        <h3>
          Glückwunsch!<br>
          Sie haben die neuen intelligenten E-Mail-Funnels erfolgreich aktiviert.
        </h3>
        <div>Ihre Kontakte profitieren ab sofort und völlig automatisch von inhaltlich relevanteren E-Mails.</div>
      </div>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            fab
            :loading="loading"
            :disabled="loading"
            v-bind="attrs"
            v-on="on"
            @click="acknowledgeDefaultSender"
          >
            <v-icon alt="Schliessen">
              mdi-close
            </v-icon>
          </v-btn>
        </template>
        <span>Schliessen</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import ACKNOWLEDGE_DEFAULT_SENDER_VERIFICATION from './queries/AcknowledgeDefaultSenderVerification.gql'
import HOPPERMATION_DEFAULT_SENDER from '@/modules/emailVerification/queries/HoppermationDefaultSender.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  data () {
    return {
      loading: false
    }
  },
  methods: {
    updateQuery (store, { data: { defaultSender } }) {
      const data = store.readQuery({ query: HOPPERMATION_DEFAULT_SENDER, variables: { companyId: this.$auth.user.companyId } })
      data.defaultSender = defaultSender
      store.writeQuery({ query: HOPPERMATION_DEFAULT_SENDER, data, variables: { companyId: this.$auth.user.companyId } })
    },
    async acknowledgeDefaultSender () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: ACKNOWLEDGE_DEFAULT_SENDER_VERIFICATION,
          variables: { companyId: this.$auth.user.companyId },
          update: this.updateQuery
        })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Fehler beim Bestätigen.' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
