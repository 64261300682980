<template>
  <div>
    <div
      v-if="isSenderVerified || isDomainVerified"
      class="d-flex"
    >
      <h2 class="mx-10 w-full align-self-center">
        Intelligente E-Mail-Funnels werden jetzt für Sie aktiviert.
      </h2>
      <v-progress-circular
        indeterminate
        :size="80"
        :width="10"
        color="primary"
        class="mx-10"
      />
    </div>
    <div v-else>
      <div class="d-flex mb-4">
        <v-icon
          v-if="defaultSenderCreated"
          class="mr-4"
          color="info"
          size="60"
        >
          mdi-information
        </v-icon>
        <v-icon
          v-else
          class="mr-4"
          color="error"
          size="60"
        >
          mdi-alert
        </v-icon>
        <div v-if="defaultSenderCreated">
          <h3 class="info--text">
            Bestätigungs-E-Mail erfolgreich an <strong>{{ defaultSender?.fromEmail }}</strong> versendet.
          </h3>
          <div>Schauen Sie jetzt in Ihr E-Mail Postfach und ggfs. in den Spam-Ordner!</div>
        </div>
        <div v-else>
          <h3 class="error--text">
            Achtung! Aktivierung der Intelligenten E-Mail-Funnels nicht abgeschlossen
          </h3>
          <div>Sie können die intelligenten E-Mail-Funnels erst nutzen, wenn Sie den Erhalt der Aktivierungs-E-Mail bestätigen.</div>
        </div>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              fab
              :loading="loading"
              :disabled="loading"
              v-bind="attrs"
              v-on="on"
              @click="checkStatus"
            >
              <v-icon alt="Aktualisieren">
                mdi-reload
              </v-icon>
            </v-btn>
          </template>
          <span>Aktualisieren</span>
        </v-tooltip>
      </div>
      <h3>Wie kann ich bestätigen?</h3>
      <ol>
        <li>Rufen Sie jetzt Ihre E-Mails ab (<strong>{{ defaultSender?.fromEmail }}</strong>).</li>
        <li>Suchen Sie die E-Mail mit Absender "Postmark-Support" (sehen Sie ggfs. auch im Spam-Ordner nach).</li>
        <li>Klicken Sie darin auf den Button "Confirm Sender Signature".</li>
      </ol>
      <p>Damit ist die Aktivierung vollständig. Der Versand der intelligenten E-Mails an Ihre Kontakte erfolgt dann völlig automatisch. Sie müssen nichts weiter tun.</p>
      <h3>Ich finde die E-Mail nicht, was kann ich tun?</h3>
      <p>
        In diesem Fall können Sie den
        <v-btn
          color="primary"
          class="text-none px-0 pb-1 body-1"
          :loading="loading"
          :disabled="loading"
          text
          x-small
          @click="resendVerificationMail"
        >
          Versand der Bestätigungs-E-Mail erneut auslösen.
        </v-btn><br>
        Befolgen Sie anschließend die oben genannten Schritte.
      </p>
    </div>
  </div>
</template>

<script>
import RESEND_HOPPERMATION_DEFAULT_SENDER_VALIDATION from './queries/ResendHoppermationDefaultSenderValidation.gql'
import GET_DOMAIN from '@/queries/GetDomain.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    defaultSenderCreated: { type: Boolean, default: false },
    defaultSender: { type: Object, default: () => null },
    checkStatus: { type: Function, required: true }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    isDomainVerified () {
      return this.domain?.isDKIMVerified && this.domain?.isReturnPathDomainVerified
    },
    isSenderVerified () {
      return this.defaultSender?.isVerified
    }
  },
  apollo: {
    domain: {
      query: GET_DOMAIN,
      variables () {
        return { companyId: this.$auth.user.companyId }
      }
    }
  },
  methods: {
    async resendVerificationMail () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: RESEND_HOPPERMATION_DEFAULT_SENDER_VALIDATION,
          variables: { companyId: this.$auth.user.companyId }
        })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Fehler beim Senden der Verifizierungsemail.' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
